<template>
  <div class="card">
    <SubTitle :title="'个人信息'"></SubTitle>
    <van-row>
      <van-col span="6">姓名</van-col>
      <van-col span="6">{{ customerData.customerName | dash }}</van-col>
      <van-col span="6">性别</van-col>
      <van-col span="6">{{ customerData.sex | dash }}</van-col>

      <van-col span="6">年龄</van-col>
      <van-col span="6">{{ customerData.age | dash }}</van-col>
      <van-col span="6">婚姻</van-col>
      <van-col span="6">{{ customerData.marriageName | dash }}</van-col>

      <van-col span="6">户籍地址</van-col>
      <van-col span="18" class="flex-between flex-ac">
        <!-- <van-tag class="fn-mr5" type="warning" size="mini">有效</van-tag> -->
        <div class="long-text">{{ addrData.permanentzAddress | dash }}</div>
        <!-- <van-icon name="edit" @click="openPopup('户籍地址', {})" /> -->
        <van-button
          v-if="addrData.permanentzAddress"
          type="info"
          size="mini"
          @click="mapNavigation(addrData.permanentzAddress)"
          >导航</van-button
        >
      </van-col>

      <van-col span="6">居住地址</van-col>
      <van-col span="18" class="flex-between flex-ac">
        <!-- <van-tag class="fn-mr5" type="warning" size="mini">有效</van-tag> -->
        <div class="long-text">{{ addrData.liveAddress | dash }}</div>
        <!-- <van-icon name="edit" @click="openPopup('居住地址', {})" /> -->
        <van-button
          v-if="addrData.liveAddress"
          type="info"
          size="mini"
          @click="mapNavigation(addrData.liveAddress)"
          >导航</van-button
        >
      </van-col>

      <van-col span="6">房产地址</van-col>
      <van-col span="18" class="flex-between flex-ac">
        <div class="long-text">{{ addrData.fcAddr | dash }}</div>
        <!-- <van-icon name="edit" @click="openPopup('房产地址', {})" /> -->
        <van-button
          v-if="addrData.fcAddr"
          type="info"
          size="mini"
          @click="mapNavigation(addrData.fcAddr)"
          >导航</van-button
        >
      </van-col>

      <van-col span="6">单位地址</van-col>
      <van-col span="18" class="flex-between flex-ac">
        <div class="long-text">{{ addrData.unitAddress | dash }}</div>
        <!-- <van-icon name="edit" @click="openPopup('单位地址', {})" /> -->
        <van-button
          v-if="addrData.unitAddress"
          type="info"
          size="mini"
          @click="mapNavigation(addrData.unitAddress)"
          >导航</van-button
        >
      </van-col>

      <van-col span="6">联系方式</van-col>
      <van-col span="18" class="flex-between flex-ac">
        <div>
          <a :href="`tel:${customerData.mobile}`">{{
            customerData.mobile | dash
          }}</a>
        </div>
        <!-- <van-icon name="edit" @click="openPopup('客户联系方式', {})" /> -->
      </van-col>
    </van-row>

    <SubTitle title="车辆信息">
      <template #right>
        <van-button type="info" size="mini" @click="goGpsList"
          >查看GPS信息</van-button
        >
      </template>
    </SubTitle>
    <van-row>
      <van-col span="6">车牌号</van-col>
      <van-col span="6">{{ customerData.carPlateNo | dash }}</van-col>
      <van-col span="6">GPS情况</van-col>
      <van-col span="6">
        {{ gpsData.warningTypeName | dash }}
      </van-col>
      <!-- <van-col span="6">车主名称</van-col>
      <van-col span="6">{{ customerData.carOwner | dash }}</van-col> -->

      <van-col span="6">车型</van-col>
      <van-col span="18">{{
        customerData.vehicleModelFullName | dash
      }}</van-col>
    </van-row>

    <SubTitle title="租后信息"> </SubTitle>
    <van-row>
      <van-col span="6">融资金额</van-col>
      <van-col span="4">{{ customerLoanData.amount | dash }}</van-col>
      <van-col span="6">融资时间</van-col>
      <van-col span="8">{{ customerLoanData.loanDate | dash }}</van-col>

      <van-col span="6">还款期数</van-col>
      <van-col span="4"
        >{{ customerLoanData.payedNo | dash }}/{{
          customerLoanData.no | dash
        }}</van-col
      >
      <van-col span="6">逾期起日</van-col>
      <van-col span="8">{{ customerLoanData.overdueDate | dash }}</van-col>

      <van-col span="6">逾期天数</van-col>
      <van-col span="4">{{ customerLoanData.overdueDay | dash }}</van-col>
      <van-col span="6">逾期期数/月还款</van-col>
      <van-col span="8">
        {{ customerLoanData.overdueNo | dash }}/{{
          customerLoanData.monthPayedAmount | dash
        }}
      </van-col>
    </van-row>

    <SubTitle title="当前逾期"></SubTitle>
    <van-row>
      <van-col span="6">逾期合计</van-col>
      <van-col span="18">{{ loanOverdueData.total | dash }}</van-col>

      <van-col span="6">逾期租金</van-col>
      <van-col span="6">{{ loanOverdueData.rentRemain | dash }}</van-col>
      <van-col span="6">
        逾期违约金
      </van-col>
      <van-col span="6">{{ loanOverdueData.interestRemain | dash }}</van-col>
    </van-row>

    <SubTitle title="结清试算">
      <template #right>
        <van-button type="info" size="mini" @click="openPopup('结清试算', {})"
          >点击试算</van-button
        >
      </template>
    </SubTitle>
    <van-row v-for="(item, index) in repayList" :key="index">
      <van-col span="6">结清金额</van-col>
      <van-col span="6">{{
        (item.trialResult && item.trialResult.repayAdvanceAmt) | dash
      }}</van-col>
      <van-col span="5">
        试算时间
      </van-col>
      <van-col span="7">{{
        item.createTime | dateFormat("YYYY-MM-DD") | dash
      }}</van-col>
    </van-row>
    <van-empty
      style="background-color: #fff;"
      image-size="50px"
      v-if="!repayList.length"
      description="暂无试算记录"
    />

    <template v-if="bankData.bankAccount">
      <SubTitle title="回款账户"></SubTitle>
      <van-row>
        <van-col span="6">回款户名</van-col>
        <van-col span="18">{{ bankData.bankName | dash }}</van-col>
        <van-col span="6">账号</van-col>
        <van-col span="18"
          >{{ bankData.bankAccount | dash
          }}<van-icon
            v-if="!bankData.isSeen"
            class="fn-ml5"
            name="eye-o"
            @click="sensitiveInfo('bank', bankData.bankAccount)"
        /></van-col>
        <van-col span="6">银行</van-col>
        <van-col span="18">{{ bankData.openAccountBank | dash }}</van-col>
      </van-row>
    </template>

    <SubTitle title="联系人信息">
      <!-- <template #right>
        <van-button type="info" size="mini">新增联系人</van-button>
      </template> -->
    </SubTitle>
    <div class="table" v-if="relatePersonData">
      <div class="row title">
        <span>联系人姓名</span><span>联系人手机号</span><span>关系</span>
      </div>
      <div
        class="row"
        v-for="(contact, index) in relatePersonData"
        :key="index"
      >
        <span>{{ contact.userName }}</span>
        <span>
          <a :href="`tel://${contact.mobile}`">{{ contact.mobile }}</a>
          <van-icon
            v-if="!contact.isSeen"
            class="fn-ml5"
            name="eye-o"
            @click="sensitiveInfo('mobile', contact.mobile, index)"
        /></span>
        <span>
          {{ contact.type | dash }}
          <!-- <van-icon name="edit" /> -->
        </span>
      </div>
    </div>

    <PopupSlot
      v-model="showPopup"
      :actionType="actionType"
      :actionItem="actionItem"
      @closePopup="closePopup"
      @confirmPopup="confirmPopup"
    >
      <van-form>
        <van-field
          v-if="['结清试算'].includes(actionType)"
          readonly
          required
          clickable
          :label="'结清日期：'"
          :value="form.valueDate"
          placeholder="请选择日期"
          @click="showPicker = true"
        />

        <template v-if="['客户联系方式'].includes(actionType)">
          <van-field
            type="number"
            label="客户联系方式："
            v-model="form.phone"
            placeholder="请输入联系方式"
          />
          <van-field name="phoneType" label="" class="form-no-boder">
            <template #input>
              <van-radio-group v-model="form.phoneType" direction="horizontal">
                <van-radio name="1">号码有效</van-radio>
                <van-radio name="2">号码无效</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            required
            type="number"
            :label="`新联系号码：`"
            v-model="form.newPhone"
            :placeholder="`请输入新联系号码`"
          >
          </van-field>
          <van-field
            required
            name="phoneAttachList"
            label="通话记录："
            class="form-no-boder"
          >
            <template #input>
              <Uploader accept="*" v-model="form.phoneAttachList" />
            </template>
          </van-field>
          <div class="fn-fs1">
            注：上传通话录音、图片或者视频证明手机号为联系人的
          </div>
        </template>

        <template
          v-if="
            ['户籍地址', '居住地址', '房产地址', '单位地址'].includes(
              actionType
            )
          "
        >
          <van-field
            :label="`${actionType}：`"
            v-model="form.address"
            :placeholder="`请输入${actionType}`"
          />
          <van-field name="addressType" label="" class="form-no-boder">
            <template #input>
              <van-radio-group
                v-model="form.addressType"
                direction="horizontal"
              >
                <van-radio name="1">地址有效</van-radio>
                <van-radio name="2">地址无效</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            required
            :label="`新${actionType}：`"
            v-model="form.newAddress"
            :placeholder="`请输入新${actionType}`"
          >
            <template #button>
              <van-button size="mini" type="info">获取定位</van-button>
            </template>
          </van-field>
          <van-field
            name="addressAttachList"
            label="地址照片："
            class="form-no-boder"
          >
            <template #input>
              <Uploader accept="image/*" v-model="form.addressAttachList" />
            </template>
          </van-field>
        </template>

        <van-popup v-model="showPicker" round position="bottom">
          <van-datetime-picker
            v-if="['结清试算'].includes(actionType)"
            v-model="currentDate"
            type="date"
            title="选择年月日"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="showPicker = false"
            @confirm="onConfirmDate"
          />
        </van-popup>
      </van-form>
    </PopupSlot>
  </div>
</template>

<script>
import dayjs from "dayjs";
import * as _ from "lodash";
import { Toast } from "vant";
import ApiDetail from "@/api/caseDetail";
import { goMapName } from "@/libs/utils";
import Api from "@/api/caseHandle";

const DEFAULT_FORM = {
  valueDate: "",
  phone: "",
  phoneType: "",
  newPhone: "",
  phoneAttachList: [],
  address: "",
  addressType: "",
  newAddress: "",
  addressAttachList: [],
};

export default {
  props: {
    detailInfo: {
      type: Object,
      required: true,
    },
    bizNo: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
    },
    addrData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      customerData: {},
      gpsData: {},
      customerLoanData: {},
      loanOverdueData: {},
      bankData: {},
      relatePersonData: [],
      loading: false,
      actionType: "",
      actionItem: {},
      showPopup: false,
      form: {
        ..._.cloneDeep(DEFAULT_FORM),
      },
      showPicker: false,
      minDate: new Date(),
      maxDate: new Date(2100, 12, 30),
      currentDate: new Date(),
      orderInfo: {},
      repayList: [],
    };
  },
  mounted() {
    this.findByBizNo();
    this.customerInfo();
    this.gpsStatus();
    this.customerLoanInfo();
    this.loanOverdueInfo();
    this.bankInfo();
    this.relatePersonInfo();
    this.repayFindPage();
  },
  methods: {
    // 结清试算记录
    repayFindPage() {
      Api.repayFindPage({
        bizNo: this.bizNo,
        pageNo: 1,
        pageSize: 100,
      }).then(({ data }) => {
        this.repayList = data.list
          .map((item) => ({
            ...item,
            trialResult: JSON.parse(item.trialResult),
          }))
          .filter(
            (i) =>
              new Date(i.trialResult.orderEndTime) >=
              new Date(this.getCurrentTime())
          );
      });
    },
    getCurrentTime() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // 月份从0开始计算，所以加1
      const day = String(today.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`; // 格式化为 YYYY-MM-DD
      return formattedDate;
    },
    // 查询借据详情
    findByBizNo() {
      Api.findByBizNo(this.bizNo).then((res) => {
        this.orderInfo = res.data || {};
      });
    },
    // 客户基本信息
    async customerInfo() {
      try {
        const res = await ApiDetail.customerInfo({ bizNo: this.bizNo });
        this.customerData = res.data || {};
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 客户GPS状态相关信息
    async gpsStatus() {
      try {
        const res = await ApiDetail.gpsStatus({ bizNo: this.bizNo });
        this.gpsData = res.data || {};
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 客户还款信息
    async customerLoanInfo() {
      try {
        const res = await ApiDetail.customerLoanInfo({ bizNo: this.bizNo });
        this.customerLoanData = res.data || {};
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 客户还款信息
    async loanOverdueInfo() {
      try {
        const res = await ApiDetail.loanOverdueInfo({ bizNo: this.bizNo });
        this.loanOverdueData = res.data || {};
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 案件回款账户信息
    async bankInfo() {
      try {
        const res = await ApiDetail.bankInfo({ bizNo: this.bizNo });
        this.bankData = res.data || {};
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 案件用户联系人信息
    async relatePersonInfo() {
      try {
        const res = await ApiDetail.relatePersonInfo({ bizNo: this.bizNo });
        this.relatePersonData = res.data || [];
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 敏感信息查询
    async sensitiveInfo(source = "mobile", ext, index) {
      try {
        const res = await ApiDetail.sensitiveInfo({
          bizNo: this.bizNo,
          source,
          ext,
        });
        if (res.success) {
          if (source == "bank") {
            this.$set(this.bankData, "bankAccount", res.data);
            this.$set(this.bankData, "isSeen", true);
          }
          if (source == "mobile") {
            this.$set(this.relatePersonData[index], "mobile", res.data);
            this.$set(this.relatePersonData[index], "isSeen", true);
          }
        }
      } catch (error) {
        Toast.fail(error);
      }
    },

    goGpsList() {
      this.$router.push({
        name: "GpsList",
        query: { bizNo: this.bizNo },
      });
    },

    mapNavigation(address) {
      // this.goMapName('重庆化龙桥地铁站', 2)
      goMapName(address, 2);
    },

    // 弹出层打开
    async openPopup(action, item) {
      this.actionType = action;
      this.actionItem = { ...item };
      this.showPopup = true;
    },
    // 关闭弹出层
    closePopup() {
      this.$set(this, "form", { ...DEFAULT_FORM });
    },
    // 弹出层确认
    async confirmPopup() {
      try {
        let { form } = this;
        if (!form.valueDate) throw "请选择结清日期";
        if (this.loading) return;
        let { resOperatorDTO = {} } = JSON.parse(
          localStorage.getItem("userAccount") || "{}"
        );
        let { name = "-", mobile = "-" } = resOperatorDTO;
        this.loading = true;
        let {
          capitalPlatformCode,
          bizNo,
          capitalIouCode,
          memberNo,
        } = this.orderInfo;
        let data = {
          bizNo,
          createBy: `${name}-${mobile}`,
          planRepayDate: form.valueDate,
          platform: capitalPlatformCode,
          capitalIouNo: capitalIouCode,
          customerNo: memberNo,
          repayType: "ADVANCE_SETTLE",
        };
        await Api.repayCalculate(data);
        this.repayFindPage();
        this.showPopup = false;
      } catch (error) {
        this.$toast.fail(error);
      } finally {
        this.loading = false;
      }
    },
    onConfirmDate(value) {
      this.$set(this.form, "valueDate", dayjs(value).format("YYYY-MM-DD"));
      this.showPicker = false;
    },
  },
};
</script>
<style lang="less" scoped>
.card {
  padding: 8px 8px 20px 8px;

  /deep/ .van-row {
    background: #fff;
    color: #333333;
    font-size: 14px;
    // border-left: 1px solid #333333;
    // border-top: 1px solid #333333;
    border-right: 1px solid #333333;
    border-bottom: 1px solid #333333;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 0;
      height: 100%;
      border-left: 1px solid #333333;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1000;
    }

    &::after {
      content: "";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }

    .van-col {
      padding: 4px;
      text-align: center;
      line-height: 24px;
      // border-right: 1px solid #333333;
      // border-bottom: 1px solid #333333;
      border-left: 1px solid #333333;
      border-top: 1px solid #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.flex-between > div {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.long-text {
          white-space: normal;
        }
      }
    }
  }

  .table {
    margin-top: 10px;
    box-sizing: border-box;
    border: 1px solid #eaecf2;
    border-radius: 2px;

    .row {
      position: relative;
      background: #fff;
      flex-direction: row;
      justify-content: space-between;
      font-size: 14px;
      display: flex;
      align-items: center;
      padding: 15px 8px;

      &.title {
        font-weight: 650;
      }

      span {
        flex: 1;
        text-align: center;
        // &:first-child {
        //   text-align: left;
        // }
        // &:last-child {
        //   text-align: right;
        // }

        a {
          font-size: 12px;
          color: #3c86ff;
          letter-spacing: 0;
          text-align: justify;
          text-decoration: underline;
        }
      }

      &::after {
        content: " ";
        position: absolute;
        bottom: 0px;
        height: 1px;
        left: 0;
        right: 0;
        background: #eaecf2;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}
</style>
